'use client';

import { TailwindConfig } from '@/utils/tailwind';
import { usePathname } from 'next/navigation';
import NextTopLoader from 'nextjs-toploader';
import { useRouter } from 'nextjs-toploader/app';

import { done } from 'nprogress';
import { useEffect } from 'react';

/**
 * This is a wrapper around the NextJS TopLoader component
 * and Nextjs router that starts and stops the top loader
 * when using router to change pages
 * @returns A router object with a push function that starts the top loader
 */
export const useTopRouter = useRouter;

export default function TopLoader() {
    const pathname = usePathname();

    useEffect(() => {
        done();
    }, [pathname]);

    return <NextTopLoader color={TailwindConfig.theme.colors.primary} height={2} />;
}
