'use client'; // Error components must be Client Components

import { Button } from '@/components/Button';
import { H2 } from '@/components/Typography/H2';
import {
    toastError,
    type NextDynamicOption,
    type NextPreferredRegionOption,
    type NextRuntimeOption,
} from '@/types/general';
import { log } from '@/utils/logger';
import { useEffect } from 'react';
import { BugIcon, HomeIcon, RotateCwIcon } from 'lucide-react';
import { useTopRouter } from '@/components/TopLoader';
import { Small } from '@/components/Typography/Small';
import * as Sentry from '@sentry/nextjs';

export const dynamic: NextDynamicOption = 'force-static';

export const runtime: NextRuntimeOption = 'nodejs';

export const preferredRegion: NextPreferredRegionOption = 'global';

export default function Error({
    error,
    // reset,
}: {
    error: Error & { digest?: string };
    reset: () => void;
}) {
    const router = useTopRouter();

    useEffect(() => {
        // Log the error to an error reporting service
        log({
            message: 'Error in a client component',
            error,
            level: 'error',
        });
    }, [error]);
    return (
        <div
            id="errorWindow"
            className="flex h-screen flex-1 flex-col items-center justify-center gap-4 text-center"
        >
            <img src="/images/sad_ghost.png" alt="Sad Ghost Image" className="size-70" />
            <div>
                <H2>Uh Oh! Something went wrong!</H2>
                <Small>A bug must be haunting you. Please help others by reporting it!</Small>
            </div>
            <div className="flex flex-row gap-4">
                <Button
                    onClick={
                        // Attempt to recover by trying to re-render the segment
                        // () => reset()
                        () => window.location.reload()
                    }
                    className="flex flex-row gap-2"
                >
                    <RotateCwIcon className="size-4" /> Reload
                </Button>
                <Button
                    variant={'outline'}
                    onClick={() => router.push('/')}
                    className="flex flex-row gap-2"
                >
                    <HomeIcon className="size-4" /> Home
                </Button>
            </div>
            <div className="flex flex-row gap-4">
                <Button
                    variant={'fancy'}
                    onClick={async () => {
                        try {
                            const feedback = Sentry.feedbackIntegration({
                                // Disable the injection of the default widget
                                autoInject: false,
                                showBranding: false,
                                isNameRequired: true,
                                isEmailRequired: true,
                                useSentryUser: {
                                    email: 'email',
                                    name: 'fullName',
                                },
                            });
                            const form = await feedback.createForm();
                            form.appendToDom();
                            form.open();
                        } catch (error) {
                            log({
                                message: 'Error in feedback form',
                                error,
                                level: 'error',
                            });
                            toastError(
                                'Oh snap! Did the report button fail to load too?? Please shoot us an email at support@pitchgost.com'
                            );
                        }
                    }}
                    className="flex flex-row gap-2"
                >
                    <BugIcon className="size-4" /> Report what happened
                </Button>
            </div>
        </div>
    );
}
